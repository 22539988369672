import React from 'react';
import {Link} from 'gatsby'
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle, Sandcastle, Clock, ClockRange, ClockStep, ClockViewModel, ImagerySplitDirection, ScreenSpaceEventHandler,ScreenSpaceEventType, Math, Cartesian2 } from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';

import CesiumNavigation from "cesium-navigation-es6";
import { backgroundColor } from 'styled-system';
import Styles from "./cesium.module.css"


class CesiumContainer extends React.Component {

  divRef = React.createRef();
  viewer = null;
  entity = null;

/// 
  // state = {isChecked: true};
  // handleClick = () => {
  //   this.setState(state => ({
  //     isChecked: !state.isChecked
  //   }),
  //   );
  //   console.log(this.state.isChecked);
  // }


  render() {
    return (
      <div>
        <div className={Styles.canvaswrap}>
          <div id="cesiumcontainerdiv">
            <div ref={this.divRef} style={{height:"400px"}}>
              <div id="slider" className={Styles.slider}></div>
              <div id="toolbar4" className={Styles.step}></div>
                    {/* <ol className={Styles.step}>
                      <li>
                        <input type="radio" id="year1" name="yearchoice" value="1970" checked />
                        <label for="year1">1970</label>
                      </li>
                      <li>
                        <input type="radio" id="year2" name="yearchoice" value="1990" />
                        <label for="year2">1990</label>
                      </li>
                      <li>
                        <input type="radio" id="year3" name="yearchoice" value="2010" />
                        <label for="year3">2010</label>
                      </li>
                    </ol> */}
            </div>
          </div>
        </div>
                      <div style={{display:"flex",justifyContent: "space-between"}}>
                <div className={Styles.buttonshita}>
                  <input className={Styles.buttononoff} type="button" id="onoff" value="Off"/>
                </div>
                <div className={Styles.buttonshita}>
                  <input className={Styles.buttononoff} type="button" id="originview" value="HOME"/>
                </div>
                <div className={Styles.buttonshita}>
                  <Link className={Styles.buttononoff}
                    to={"/"+this.props.no+"/kokudof"}
                  >
                    Full
                  </Link>
                </div>
                <div>
                  <input type="button" id="googlemapopen"  className={Styles.buttononoff} value="GoogleMap" />
                </div>
              </div>
              <div>
                <a className={Styles.buttononoff} href={this.props.downdir} download>KMLダウンロード</a>
              </div>

      </div>
    );
  }
  // render() {
  //   return <div><div id="cesiumContainer"  style={{width:"100%", height:"500px"}}></div></div>;
  // }


  componentDidMount() {

    var clock = new Clock({
        startTime : JulianDate.fromIso8601('1950-08-30T11:56:04+08'),
        stopTime : JulianDate.fromIso8601('2030-08-30T11:56:04+08'),
        currentTime : JulianDate.fromIso8601('2010-08-30T11:56:04+08'),
        clockRange : ClockRange.LOOP_STOP,
        clockStep : ClockStep.SYSTEM_CLOCK_MULTIPLIER
    });

    const viewer = new Viewer(this.divRef.current, {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      timeline : true,
      animation : false,
      fullscreenButton: false,
      homeButton: false,
      vrButton: false,
      geocoder:false,
      sceneModePicker:false,
      navigationHelpButton:false,
      infoBox : false,
      selectionIndicator : false,
      shadows : false,
      clockViewModel: new ClockViewModel(clock)
    });

console.log(viewer.canvas);

var cesiumcontainerdiv = document.getElementById("cesiumcontainerdiv") 
viewer.canvas.width = cesiumcontainerdiv.clientWidth;
viewer.canvas.height = cesiumcontainerdiv.clientHeight;


/// スライダー //////////
var layers = viewer.imageryLayers;
var chiriinMap = layers.addImageryProvider(
  new UrlTemplateImageryProvider({
    // url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
//      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
    credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
  }),
);

chiriinMap.splitDirection = ImagerySplitDirection.LEFT;

var slider = document.getElementById("slider");

viewer.scene.imagerySplitPosition = slider.offsetLeft / slider.parentElement.offsetWidth;

var handler = new ScreenSpaceEventHandler(slider);

var moveActive = false;

function move(movement){
  if(!moveActive){
    return;
  }
var relativeOffset = movement.endPosition.x;

var splitPosition = (slider.offsetLeft + relativeOffset) / slider.parentElement.offsetWidth;
slider.style.left = 100.0 * splitPosition + "%";
viewer.scene.imagerySplitPosition = splitPosition;
}

handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.LEFT_DOWN);
handler.setInputAction(function () {
  moveActive = true;
}, ScreenSpaceEventType.PINCH_START);

handler.setInputAction(move, ScreenSpaceEventType.MOUSE_MOVE);
handler.setInputAction(move, ScreenSpaceEventType.PINCH_MOVE);

handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.LEFT_UP);
handler.setInputAction(function () {
  moveActive = false;
}, ScreenSpaceEventType.PINCH_END);
////////// スライダー ///

/// GoogleMapを開く //////////
var win;
var buttongoogle = document.getElementById('googlemapopen');
buttongoogle.addEventListener('click', function() {
  var cartographic = viewer.camera.positionCartographic;
  console.log(cartographic);
          var lon = Math.toDegrees(cartographic.longitude).toFixed(5);
          var lat = Math.toDegrees(cartographic.latitude).toFixed(5); 
          var url = 'http://maps.google.co.jp/maps?q=' + lat + ',' + lon;
          win = window.open(url ,"gmap" ,'width=800,height=600');
}, false);
////////// GoogleMapを開く ///


console.log(viewer.camera.HeadingPitchRange);


/// timelineをtrueにしたまま画面から消す //////////
    // viewer.timeline.container.style.display = 'none';
    // viewer.timeline.resize();
////////// timelineをtrueにしたまま画面から消す ///

/// 地形へのライティングを使う //////////
// viewer.scene.globe.enableLighting = true;
////////// 地形へのライティングを使う ///


    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };

    var dataSource1 = new KmlDataSource.load(this.props.dir,options);

    // document.getElementById('toolbar').innerHTML =
    // '<label><input type="checkbox" id="showCheckbox" /> Show CZML</label>';


    // var checkbox = document.getElementById('showCheckbox');
    // checkbox.addEventListener('change', function() {
    //     // Checkbox state changed.
    //     if (checkbox.checked) {
    //         // Show if not shown.
    //         if (!viewer.dataSources.contains(dataSource1)) {
    //             viewer.dataSources.add(dataSource1).show = true;
    //             viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    //         }
    //     } else {
    //         // Hide if currently shown.
    //         // if (viewer.dataSources.contains(dataSource1)) {
    //           viewer.dataSources.removeAll();
    //           // viewer.dataSources.remove(dataSource1);
    //         // }
    //     }
    // }, false);


    viewer.dataSources.add(dataSource1).show = true;
    viewer.zoomTo(dataSource1
      // , new HeadingPitchRange(0, -1, 2000000)
      );



/// 年度を選択するラジオボタン //////////
document.getElementById('toolbar4').innerHTML =
'<ol className={Styles.step}><li><input type="radio" id="year1" name="yearchoice" value="1970" checked /><label for="year1">1970</label></li><li><input type="radio" id="year2" name="yearchoice" value="1990" /><label for="year2">1990</label></li></ol>';
{/* <li><input type="radio" id="year3" name="yearchoice" value="2010" /><label for="year3">2010</label></li> */}

var radio1 = document.getElementById('year1');
radio1.addEventListener('click', function() {
  // viewer.dataSources.removeAll();
  // viewer.dataSources.add(dataSource1).show = true;
  // viewer.dataSources.add(dataSource1);

  // viewer.clock.currentTime = new JulianDate.fromIso8601('1990-10-10T11:56:04+08');
  var aTime = JulianDate.fromIso8601('2000-10-10T11:56:04+08');
  viewer.timeline.updateFromClock();
  viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 60000, new JulianDate()));
}, false);

var radio2 = document.getElementById('year2');
radio2.addEventListener('click', function() {
  // viewer.dataSources.removeAll();
  // viewer.dataSources.add(dataSource1).show = true;
  viewer.clock.currentTime = new JulianDate.fromIso8601('2018-10-10T11:56:04+08');
            var aTime = JulianDate.fromIso8601('2018-10-10T11:56:04+08');
            viewer.timeline.updateFromClock();
            viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 60000, new JulianDate()));
}, false);

// var radio3 = document.getElementById('year3');
// radio3.addEventListener('click', function() {
//   var aTime = JulianDate.fromIso8601('2010-10-10T11:56:04+08');
//   viewer.clock.currentTime = new JulianDate.fromIso8601('2010-10-10T11:56:04+08');
//   viewer.timeline.updateFromClock();
//   viewer.timeline.zoomTo(aTime,JulianDate.addSeconds(aTime, 60000, new JulianDate()));
// }, false);
////////// 年度を選択するラジオボタン ///



/// KMLのON/OFFボタン //////////
    // document.getElementById('toolbar2').innerHTML =
    // '<div className={Styles.buttononoff}><input type="button" id="onoff" value="Off"/></div>';

    var buttona = document.getElementById('onoff');
    buttona.addEventListener('click', function() {
        let currentvalue = document.getElementById('onoff').value;
        // buttona state changed.
        if (currentvalue == "On") {
            // Show if not shown.
            document.getElementById("onoff").value="Off"
            if (!viewer.dataSources.contains(dataSource1)) {
                viewer.dataSources.add(dataSource1).show = true;
                // viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
            }
        } else {
            // Hide if currently shown.
            // if (viewer.dataSources.contains(dataSource1)) {
              document.getElementById("onoff").value="On"
              viewer.dataSources.removeAll();
              // viewer.dataSources.remove(dataSource1);
            // }
        }
    }, false);
////////// KMLのON/OFFボタン ///







/// 視点を戻すボタン //////////
    // document.getElementById('toolbar3').innerHTML =
    // '<input type="button" id="originview" value="初期視点"/>';

    var buttona = document.getElementById('originview');
    buttona.addEventListener('click', function() {
                viewer.flyTo(dataSource1, new HeadingPitchRange(0, -1, 50000));
    }, false);
////////// 視点を戻すボタン ///

    



    // var dataSourcePromise = viewer.dataSources
    // .add(KmlDataSource.load(this.props.dir,options))

    // viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));




    // .then(function (dataSource) {
    //   viewer.clock.shouldAnimate = false;})
    ;
    
    // var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir,options));



  



    viewer._cesiumWidget._creditContainer.style.display = "none";



/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

  }
 
  // componentWillUnmount() {
  //   this.viewer.destroy();
  // }


}

export default CesiumContainer

